<template>
  <b-card v-if="studentInfo">
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="refPreviewEl"
            rounded
            :src="studentInfo.avatar"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="avatar"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->
        <b-card-text>Cho phép file JPG, GIF hoặc PNG. Kích thước tối đa 800kB</b-card-text>
      </b-media-body>
    </b-media>

    <b-form class="mt-2">
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Mã học viên"
            label-for="student_code"
          >
            <b-form-input
              id="student_code"
              v-model="studentInfo.student_code"
              name="student_code"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Họ và tên"
            label-for="student_name"
          >
            <b-form-input
              id="student_name"
              v-model="studentInfo.student_name"
              name="student_name"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Giới tính"
            label-for="student_gender_detail"
          >
            <v-select
              v-model="studentInfo.gender"
              label="name"
              :options="allGenders"
              :reduce="option => +option.code"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="CMT/CCCD"
            label-for="student_identify_code"
          >
            <b-form-input
              id="student_identify_code"
              v-model="studentInfo.student_identify_code"
              name="student_identify_code"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Ngày sinh"
            label-for="student_birthday"
          >
            <flat-pickr
              v-model="studentInfo.student_birthday"
              :config="config"
              class="form-control"
              name="student_birthday"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Nơi sinh"
            label-for="student_birth_place"
          >
            <b-form-input
              id="student_birth_place"
              v-model="studentInfo.student_birth_place"
              name="student_birth_place"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Quê quán"
            label-for="student_hometown"
          >
            <b-form-input
              id="student_hometown"
              v-model="studentInfo.student_hometown"
              name="student_hometown"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Loại khuyết tật"
            label-for="student_disability"
          >
            <v-select
              v-model="studentInfo.disability"
              label="name"
              :options="allFactDisabilities"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Quốc tịch"
            label-for="student_nationality"
          >
            <v-select
              v-model="studentInfo.nationality"
              label="name"
              :options="allFactNationalities"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Tỉnh/thành phố"
            label-for="student_province"
          >
            <v-select
              v-model="studentInfo.province_code"
              label="name"
              :options="allFactProvinces"
              :reduce="option => option.code"
              @input="onProvinceChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Quận/huyện"
            label-for="student_district"
          >
            <v-select
              v-model="studentInfo.district_code"
              label="name"
              :options="allFactDistricts"
              :reduce="option => option.code"
              @input="onDistrictChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Phường/xã"
            label-for="student_ward"
          >
            <v-select
              v-model="studentInfo.ward_code"
              label="name"
              :options="allFactWards"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Email"
            label-for="student_email"
          >
            <b-form-input
              id="student_email"
              v-model="studentInfo.student_email"
              name="student_email"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Số điện thoại"
            label-for="student_mobile"
          >
            <b-form-input
              id="student_gender_detail"
              v-model="studentInfo.student_mobile"
              name="student_mobile"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Số bảo hiểm"
            label-for="student_social_insurance"
          >
            <b-form-input
              id="student_social_insurance"
              v-model="studentInfo.student_social_insurance"
              name="student_social_insurance"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Dân tộc"
            label-for="student_ethnic"
          >
            <v-select
              v-model="studentInfo.ethnic_code"
              label="name"
              :options="allFactEthnics"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Họ tên cha"
            label-for="fatherFullName"
          >
            <b-form-input
              id="fatherFullName"
              v-model="studentInfo.fatherFullName"
              name="fatherFullName"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Năm sinh cha"
            label-for="fatherYearOfBirth"
          >
            <b-form-input
              id="fatherYearOfBirth"
              v-model="studentInfo.fatherYearOfBirth"
              name="fatherYearOfBirth"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Nghề nghiệp cha"
            label-for="fatherOccupation"
          >
            <b-form-input
              id="fatherOccupation"
              v-model="studentInfo.fatherOccupation"
              name="fatherOccupation"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Số điện thoại cha"
            label-for="fatherPhoneNumber"
          >
            <b-form-input
              id="fatherPhoneNumber"
              v-model="studentInfo.fatherPhoneNumber"
              name="fatherPhoneNumber"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Họ tên mẹ"
            label-for="motherFullName"
          >
            <b-form-input
              id="motherFullName"
              v-model="studentInfo.motherFullName"
              name="motherFullName"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Năm sinh mẹ"
            label-for="motherYearOfBirth"
          >
            <b-form-input
              id="motherYearOfBirth"
              v-model="studentInfo.motherYearOfBirth"
              name="motherYearOfBirth"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Nghề nghiệp mẹ"
            label-for="motherOccupation"
          >
            <b-form-input
              id="motherOccupation"
              v-model="studentInfo.motherOccupation"
              name="motherOccupation"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Số điện thoại mẹ"
            label-for="motherPhoneNumber"
          >
            <b-form-input
              id="motherPhoneNumber"
              v-model="studentInfo.motherPhoneNumber"
              name="motherPhoneNumber"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Tôn giáo"
            label-for="student_religion"
          >
            <v-select
              v-model="studentInfo.religion_code"
              label="name"
              :options="allFactReligions"
              :reduce="option => option.code"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Ngày vào đoàn"
            label-for="student_date_youth_group"
          >
            <flat-pickr
              v-model="studentInfo.date_youth_group"
              :config="config"
              class="form-control"
              name="student_date_youth_group"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Ngày vào đảng"
            label-for="student_date_dangcongsan_group"
          >
            <flat-pickr
              v-model="studentInfo.date_dangcongsan_group"
              :config="config"
              class="form-control"
              name="student_date_dangcongsan_group"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Ngày vào đảng chính thức"
            label-for="student_date_dangcongsan_group_chinhthuc"
          >
            <flat-pickr
              v-model="studentInfo.date_dangcongsan_group_chinhthuc"
              :config="config"
              class="form-control"
              name="student_date_dangcongsan_group_chinhthuc"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Đã học lớp cảm tỉnh đảng"
            label-for="has_learn_party"
          >
            <v-select
              v-model="studentInfo.has_learn_party"
              :options="hasLearnPartySelect"
              :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="onUpdateStudent"
            >
              <span class="text-nowrap text-right">
                <feather-icon icon="CheckIcon" /> Lưu
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="exportHoSo"
            >
              <span class="text-nowrap text-right"><feather-icon icon="DownloadIcon" /> Tải excel</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row />
    </b-form>

    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      no-wrap
      :show="isLoading"
    />
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BFormGroup,
  BRow, BForm, BMediaAside, BMedia, BLink, BImg, BMediaBody, BFormFile, BCardText,
  BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { HAS_LEARN_PARTY } from '@/const/status'
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from 'flatpickr/dist/l10n'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { Flag } from '@/const/flag'

export default {
  name: 'CurriculumVitaeInfo',
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BCard,
    BCol,
    BRow,
    flatPickr,
    BMedia,
    BMediaAside,
    BLink,
    BImg,
    BMediaBody,
    BFormFile,
    BCardText,
    BOverlay,
  },
  props: {
    studentInfo: {
      type: Object,
      default: null,
    },
    studentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      isCreateReceipt: true,
      filter: {
        student_id: this.$route.params.id || this.studentId,
      },
      revenue_fields: [
        { key: 'semester_name', label: 'Kỳ học' },
        { key: 'subject_name', label: 'Học phần' },
        { key: 'total_credit_num', label: 'Số tiết' },
        { key: 'amount', label: 'Số tiền' },
      ],
      revenue_items: [],
      exemption_fields: [
        { key: 'semester_name', label: 'Kỳ học' },
        { key: 'revenue_name', label: 'Loại miễn giảm' },
        { key: 'amount', label: 'Số tiền' },
      ],
      exemption_items: [],
      config: {
        wrap: true,
        enableTime: false,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        locale: FlatpickrLanguages.vn,
        static: false,
        // minDate: 'today',
      },
      avatar: null,
      genderSelect: [],
    }
  },
  computed: {
    ...mapGetters({
      dataExport: 'curriculumVitae/dataExport',
      allFactEthnics: 'curriculumVitae/allFactEthnics',
      allFactDisabilities: 'curriculumVitae/allFactDisabilities',
      allFactReligions: 'curriculumVitae/allFactReligions',
      allFactProvinces: 'curriculumVitae/allFactProvinces',
      allFactDistricts: 'curriculumVitae/allFactDistricts',
      allFactWards: 'curriculumVitae/allFactWards',
      allFactNationalities: 'curriculumVitae/allFactNationalities',
      allGenders: 'factGender/dataSources',
    }),
    hasLearnPartySelect() {
      return HAS_LEARN_PARTY.map(item => ({ value: item.value, label: item.label }))
    },
  },
  watch: {
    async studentInfo(val) {
      if (val) {
        this.isLoading = true
        try {
          await Promise.all([
            this.getFactEthnics(),
            this.getFactDisabilities(),
            this.getFactReligions({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
            this.getFactProvinces({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
            this.getFactNationalities({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
            this.getGenders({ currentPage: 1, itemsPerPage: 100 }),
            this.getDistrict({ province_code: this.studentInfo.province_code }),
            this.getWard({
              province_code: this.studentInfo.province_code,
              district_code: this.studentInfo.district_code,
            }),
          ])
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getDataExport: 'curriculumVitae/downloadExport',
      getFactEthnics: 'curriculumVitae/getFactEthnics',
      getFactDisabilities: 'curriculumVitae/getFactDisabilities',
      getFactReligions: 'curriculumVitae/getFactReligions',
      getFactProvinces: 'curriculumVitae/getFactProvinces',
      getFactNationalities: 'curriculumVitae/getFactNationalities',
      updateStudentInfo: 'curriculumVitae/updateStudentInfo',
      getDistrict: 'curriculumVitae/getDistrict',
      getWard: 'curriculumVitae/getWard',
      getGenders: 'factGender/getDataSources',
    }),
    async onProvinceChange(event) {
      this.studentInfo.province_code = event
      await this.getDistrict({ province_code: event })
      this.studentInfo.district_code = null
      this.studentInfo.ward_code = null
    },
    async onDistrictChange(event) {
      this.studentInfo.district_code = event
      await this.getWard({ province_code: this.studentInfo.province_code, district_code: event })
      this.studentInfo.ward_code = null
    },
    async exportHoSo() {
      await this.getDataExport(this.filter)

      const a = document.createElement('a')
      document.body.appendChild(a)
      const blob = new Blob([this.dataExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = `ho_so_sinh_vien_${this.studentInfo.student_name}.xlsx`
      a.click()
      window.URL.revokeObjectURL(url)
    },
    async onUpdateStudent() {
      this.isLoading = true
      try {
        const response = await this.updateStudentInfo(this.studentInfo)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
