<template src="./index.html" />

<script>
import {
  BButton,
  BCol,
  BRow,
  BForm,
  BBreadcrumb,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  ModalPlugin,
  BButtonGroup,
} from 'bootstrap-vue'
import FormClass from '@/views/admin/category/class/FormClass.vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { STATUSES } from '@/const/status'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import StudentClass from '@/views/student-class/StudentClass.vue'
import { getUser } from '@/auth/utils'
import StudentRegisterSubject from '@/views/student-class/StudentRegisterSubject.vue'
import StudentReserve from '@/views/student-class/student-reserve/StudentReserve.vue'
import ConductPoint from '@/views/admin/category/class/enter-conduct-point/ConductPoint.vue'
import { PermissionCode, ResourceCode } from '@/const/code'
import { hasPermissionForResource } from '@/utils'
import Ripple from 'vue-ripple-directive'
import Notification from '@/views/notification/Notification.vue'
import TeacherClass from '@/views/teacher-class/TeacherClass.vue'

export default {
  name: 'Class',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    Notification,
    StudentClass,
    VueGoodTable,
    BButton,
    BCol,
    BRow,
    BForm,
    BBreadcrumb,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    FormClass,
    ModalPlugin,
    VBTooltip,
    vSelect,
    BButtonGroup,
    StudentRegisterSubject,
    ConductPoint,
    StudentReserve,
    TeacherClass,
  },
  props: {

  },
  data() {
    return {
      user: getUser(),
      loading: true,
      serverParams: {
        columnFilters: {},
        filterSearch: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      listTrangThai: [],
      table: {
        fields: [
          {
            field: 'className',
            label: 'Tên lớp',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
            },
            sortable: false,
            type: 'string',
          },
          {
            field: 'code',
            label: 'Mã lớp',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
            },
            sortable: false,
            type: 'string',
          },
          {
            field: 'trainingSystemName', label: 'Chương trình đào tạo/Bồi dưỡng', type: 'string', sortable: false,
          },
          {
            field: 'courseName', label: 'Khóa học', type: 'string', sortable: false,
          },
          {
            field: 'consultantName', label: 'GVCN/CVHT', type: 'string', sortable: false,
          },
          {
            field: 'status', label: 'Trạng thái', sortable: false,
          },
          {
            field: 'isDualMajor', label: '⛉', sortable: false, tdClass: 'text-right', thClass: 'text-center',
          },
          {
            field: 'action', label: '', sortable: false, tdClass: 'text-right',
          },
        ],
      },
      dataSendClass: {},
    }
  },
  computed: {
    ...mapGetters({
      classes: 'classes/classes',
      total: 'classes/total',
      statuses: 'classes/statuses',
      majors: 'dropdown/majors',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      departments: 'dropdown/departments',
      teachers: 'dropdown/teachers',
      consultants: 'dropdown/consultants',
    }),
    conductPointReadable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.STUDENT_CONDUCT)
    },
    hasCreatePermissionForClass() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.CLASSES)
    },
    hasUpdatePermissionForClass() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.CLASSES)
    },
    studentClassReadable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.STUDENT_CLASS)
    },
    teacherClassReadable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.TEACHER_CLASS)
    },
  },
  async created() {
    this.listTrangThai = STATUSES
    await this.getInitData()
    if (this.user.teacherId) {
      this.setTeachers([{
        value: this.user.teacherId,
        label: this.user.fullName,
      }])
      this.setConsultants([{
        value: this.user.teacherId,
        label: this.user.fullName,
      }])
      this.serverParams.filterSearch.teacherId = this.user.teacherId
      this.serverParams.filterSearch.consultantId = this.user.teacherId
    }
    await this.getData(this.serverParams)
  },
  methods: {
    ...mapMutations({
      setTeachers: 'dropdown/SET_TEACHERS',
      setConsultants: 'dropdown/SET_CONSULTANTS',
    }),
    ...mapActions({
      getInit: 'classes/getInit',
      getClasses: 'classes/getClasses',
      createClass: 'classes/createClass',
      updateClass: 'classes/updateClass',
      deleteClass: 'classes/deleteClass',
      readMajors: 'dropdown/getMajors',
      getTrainingSystem: 'dropdown/getTrainingSystems',
      getCourse: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getTeachers: 'dropdown/getTeachers',
      getConsultants: 'dropdown/getConsultants',
    }),
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    async onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      await this.getData(this.serverParams)
    },
    async onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      await this.getData(this.serverParams)
    },
    async onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      await this.getData(this.serverParams)
    },

    async onColumnFilter(params) {
      this.updateParams(params)
      await this.getData(this.serverParams)
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    async deleteItem(item) {
      this.$swal
        .fire({
          title: 'Xóa bản ghi này?',
          text: 'Bạn sẽ không thể khôi phục dữ liệu này sau khi xóa !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
          cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(async result => {
          if (result.isConfirmed) {
            const response = await this.deleteClass(item.classId)
            if (response) {
              await this.getData(this.serverParams)
              this.showToast('Đã xóa thành công!', 'CheckIcon', 'success', '')
            } else {
              this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'warning', '')
            }
          }
        })
    },

    async btnSearchClick() {
      await this.getData(this.serverParams)
    },

    openModal() {
      this.dataSendClass = {}
      this.$refs['modal-class'].show()
    },

    editClass(data) {
      this.dataSendClass = data
      this.$refs['modal-class'].show()
    },

    openStudentRegisterSubject(data) {
      this.dataSendClass = data
      this.$refs['modal-student-subject'].show()
    },
    openStudentReserve(data) {
      this.dataSendClass = data
      this.$refs['modal-student-reserve'].show()
    },
    listStudent(data) {
      this.dataSendClass = data
      this.$refs['modal-student'].show()
    },
    listTeachersClasses(data) {
      this.dataSendClass = data
      this.$refs['modal-teacher-class'].show()
    },
    openNotifications(data) {
      this.dataSendClass.groupId = data.id
      this.dataSendClass.type = 1
      this.$refs['modal-class-notification'].show()
    },
    async saveDataClass(data) {
      const dataSend = {
        id: data.classId ? data.classId : null,
        name: data.className,
        code: data.code,
        orderNo: data.orderNo,
        majorId: data.majorId,
        consultantId: data.consultantId ? data.consultantId : null,
        teacherId: data.teacherId ? data.teacherId : null,
        status: data.status,
        courseId: data.courseId,
        startDate: data.startDate,
        endDate: data.endDate,
      }
      this.loading = true
      try {
        const response = data.classId ? await this.updateClass(dataSend) : await this.createClass(dataSend)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('modal-class')
            await this.getData(this.serverParams)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false
      }
    },

    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
    getDualMajorMark(id) {
      return (id) ? 'Ngành 2' : ''
    },

    async getInitData() {
      await Promise.all([
        this.getTrainingSystem({ organizationId: this.user.orgId }),
        // this.getDepartments({ organizationId: this.user.orgId }),
        this.getTeachers({ }),
        this.getConsultants({ }),
      ])
    },

    async onChangeTrainingSystem(id) {
      await this.getCourse({ trainingSystemId: id })
    },

    async onChangeDepartment(id) {
      await Promise.all([
        // this.readMajors({ departmentId: id }),
        this.getTeachers({ departmentId: id }),
        this.getConsultants({ departmentId: id }),
      ])
    },

    async getData(params) {
      try {
        this.loading = true
        await this.getClasses({
          currentPage: params.page,
          itemsPerPage: params.perPage,
          organizationId: getUser().orgId,
          trainingSystemId: params.filterSearch.trainingSystemId,
          courseId: params.filterSearch.courseId,
          departmentId: params.filterSearch.departmentId,
          majorId: params.filterSearch.majorId,
          teacherId: params.filterSearch.teacherId,
          consultantId: params.filterSearch.consultantId,
          status: params.filterSearch.status,
          name: params.columnFilters.name,
          code: params.columnFilters.code,
        })
        this.totalRecords = this.total
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false
      }
    },
    // eslint-disable-next-line consistent-return
    openStudentConductPoint(data) {
      this.dataSendClass = data
      this.$bvModal.show('conductPointModal')
    },

    checkRightToUpdate(data) {
      if (!this.hasUpdatePermissionForClass) return false
      if (!this.user.teacherId) return true
      return data.teacherId === this.user.teacherId || data.consultantId === this.user.teacherId
    },

    getTeacherAndConsultantName(data) {
      if (data.teachers.length > 0 && data.consultantName) {
        return `${data.teachers.map(item => item.name).join(', ')} / ${data.consultantName}`
      }
      if (data.teachers.length > 0) {
        return data.teachers.map(item => item.name).join(', ')
      }
      if (data.consultantName) {
        return data.consultantName
      }
      return ''
    },
  },
}
</script>
<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
