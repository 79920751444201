<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col cols="12">
              <div class="text-right">
                <b-form-group>
                  <b-button
                    v-if="creatable"
                    v-b-modal.teacherClassSaveModal
                    variant="success"
                    class="ml-md-1 mt-1 mt-md-0"
                    @click="onCreate"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="align-text-top"
                    /> Thêm mới
                  </b-button>
                </b-form-group>
              </div>
            </b-col>
            <!-- Per Page -->
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-0">
                <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                  đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                </span>
              </div>
              <vue-good-table
                mode="remote"
                class="my-table"
                line-numbers
                style-class="vgt-table striped bordered research-table"
                row-style-class="vgt-row"
                :columns="columns"
                :rows="teacherClasses"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'action'">
                    <b-button
                      v-if="updatable"
                      v-b-modal.teacherClassSaveModal
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="onEdit(props.row)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-if="deletable"
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onDelete(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </span>
                  
                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
                
                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                    </div>

                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <TeacherClassSave
      ref="saveFormRef"
      :item="currentTeacherClass"
      :class-id="classIdTemp"
      @succeed="onSucceed"
    />
  </b-container>

</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import TeacherClassSave from '@/views/teacher-class/TeacherClassSave.vue'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'teacherClass',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormGroup,
    VueGoodTable,
    vSelect,
    TeacherClassSave,
  },
  props: {
    mainData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      organizationSelected: {},
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        classId: null,
        status: 1,
        sort: '',
      },
      currentTeacherClass: undefined,
      classIdTemp: null,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Giảng viên chủ nhiệm',
          field: 'employeeName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Khoa',
          field: 'departmentName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Số tiết thực hiện',
          field: 'lessonNum',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Ngày bắt đầu',
          field: 'startDate',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Ngày kết thúc',
          field: 'endDate',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      teacherClasses: 'teacherClass/dataLists',
      totalRows: 'teacherClass/totalRows',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.TEACHER_CLASS)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.TEACHER_CLASS)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.TEACHER_CLASS)
    },
  },
  async created() {
    this.isLoading = true
    try {
      this.filter.classId = this.mainData.classId
      await this.readDataTeacherClass(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      readDataTeacherClass: 'teacherClass/getData',
      deleteData: 'teacherClass/deleteData',
    }),
    onCreate() {
      this.currentTeacherClass = undefined
      this.classIdTemp = this.mainData.classId
    },
    onEdit(teacherClass) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = teacherClass
      this.currentTeacherClass = rest
    },
    onDelete(teacherClass) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa Giảng viên chủ nhiệm <span class="text-danger">${teacherClass.employeeName}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteData(teacherClass.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.readDataTeacherClass(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getTeacherClassFromStore()
    },
    async getTeacherClassFromStore() {
      this.isLoading = true
      try {
        await this.readDataTeacherClass(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getTeacherClassFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'orderNo') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getTeacherClassFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ searchTerm: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getTeacherClassFromStore()
    },
    async onSucceed() {
      await this.getTeacherClassFromStore()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
